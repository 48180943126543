import { useState } from 'react';
import { useHistory } from "react-router-dom"
import * as locales from '@mui/material/locale';
import { Toolbar, Box, Typography, IconButton, Container, MenuItem, Menu, Stack, ImageListItem, CssBaseline } from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import { MenuLayout } from '../menu-layout/MenuLayout';
import { SecurityUtil } from '../../../util/SecurityUtil';
import ContainerLayout from '../ContainerLayout';
import { ICON } from '../../components/form/icons/Icons';
import { makeStyles } from '@mui/styles';
import logo from '../../../assets/img/logo.png';

// const Copyright = (props) => {

//   const useStyles = makeStyles({
//     img: {
//       objectFit: 'cover',    
//       paddingTop: '10px',
//       height: '30px !important',
//       width: '30px !important'
//     },
//   });

//   const classes = useStyles();

//   return (
//     // <Stack direction="row" spacing={2}>
//       <Typography variant="body2" align="center" {...props}>
//         {/* <ImageListItem> */}
//           <img
//             className={classes.img}
//             src={logo}
//             loading="lazy"
//             // height="10px !important"
//             // width="15px !important"
//             alt='imgDash'
//           />
//         {/* </ImageListItem> */}
//         {' Copyright © '}
//         <Link color="inherit">
//         {'jbcleaning.com'}      
//         </Link>{' '}
//         {new Date().getFullYear()}
//         {'.'}
//       </Typography>
//     // </Stack>
    
    
//   );
// }

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: 'rgb(19, 47, 76)',
  color: 'white',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen, 
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: 'rgb(19, 47, 76)',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      backgroundColor: 'rgb(19, 47, 76)',
      color: 'white',
      width: drawerWidth,
      overflowY: 'scroll',
      height: '100vh',
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
      },
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        overflowY: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: '53px',
        [theme.breakpoints.up('sm')]: {
          width: '53px',
        },
      }),
    },
  }),
);

export const DashboardLayout = () => {
  const { push } = useHistory();
  const [ open, setOpen ] = useState(true);
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ options, setOptions ] = useState({})
  // const [ mode, setMode ] = useState('light')

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuBar = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const theme = createTheme({
    palette: {
      // mode: 'dark',
      primary: {
        main: '#132F4C',
      },
    },
    
    // components: {
      // MuiTableHead: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: mode == 'dark' ? 'background.default' : '#132F4C',
      //       color: mode == 'dark' ? '#FFFFFF' : 'text.primary'
      //     }
      //   },
      // },
      // MuiTableBody: {
      //   styleOverrides: { 
      //     root: {
      //         backgroundColor: mode == 'dark' ? 'background.default' : '#FFFFFF',
      //         color: mode == 'dark' ? '#FFFFFF' : '#132F4C'
      //     },
      //   },
      // },
    // },
    
  }, locales['enUS'])
 

  const useStyles = makeStyles({
    img: {
      objectFit: 'cover',    
      height: '80px !important',
      width: '100%',
      display: (!open? 'none' : 'block')
    },
  });

  const classes = useStyles();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const logout = () => SecurityUtil.setLogout();

  const getProfilesUser = (data) => {
    setOptions({...data})
  }

  const getHomeUrl = () => push(options.urlHome || '/')

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar sx={{ pr: '24px', }} variant="dense">
            <IconButton edge="start" onClick={toggleDrawer} sx={{ color: 'white', marginRight: '36px', ...(open && { display: 'none' }), }}>
              {ICON.MENU}
            </IconButton>
            <IconButton edge="start" onClick={getHomeUrl} sx={{ color: 'white' }}> {ICON.HOME} </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
              {options?.option ? options?.option : ''}
            </Typography>
            <Typography component="h1" variant="h6" color="inherit" sx={{ flexGrow: 1, textAlign: 'right', marginRight: '10px' }}>
            { SecurityUtil.getUser() } 
            </Typography>
            <IconButton edge="start" onClick={handleMenuBar} sx={{ color: 'white' }}> {ICON.ACCOUNT} </IconButton>   
            <Menu id="basic-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} >
              <MenuItem onClick={logout}> {ICON.ON} Log out</MenuItem>
            </Menu> 
          </Toolbar>
        </AppBar>
        {/* <Box sx={{ overflowY: 'auto' }}> */}
          <Drawer variant="permanent" open={open} >
            <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent:"center", px: [1], }} variant="dense" spacing={2}>
              <Stack spacing={3} direction="row" justifyContent="center" alignItems="center">
                  <Typography component="h6"></Typography>
                  {
                    open &&
                    <ImageListItem>
                      <img
                          className={classes.img}
                          src={logo}
                          loading="lazy"
                          height="80px !important"
                          width="auto"
                          alt="imgList"
                        />
                    </ImageListItem>
                  }
                  
                  <Typography component="h6"></Typography>    
                  <IconButton edge="start" onClick={toggleDrawer} sx={{ color: 'white' }}>
                    {ICON.LEFT}
                  </IconButton>          
              </Stack>
            </Toolbar>
            <MenuLayout open={open} options={options}/>
          </Drawer>
        {/* </Box> */}
        
        <Box
          // component="main"
          // sx={{
          //   backgroundColor: '#E0E0E0',
          //   flexGrow: 1,
          //   height: '100vh',
          //   overflow: 'auto',
          //   width: `calc(100% - ${drawerWidth}px)`,
          //   pt: 9
          // }}
          component="main"
          sx={{
            // backgroundColor: (theme) =>
            //   theme.palette.mode === 'light'
            //     ? theme.palette.grey[100]
            //     : theme.palette.grey[900],
            backgroundColor: '#E0E0E0',
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
        <Toolbar />
        <Container maxWidth="xxl">
              <ContainerLayout getProfiles={getProfilesUser}/>
              {/* <Copyright sx={{ top: 'auto', bottom: 0, position: 'absolute', alignItems: 'center', pl: open ? 80 : 80 }} /> */}
        </Container>
         
        </Box>
      </Box>
      
    </ThemeProvider>
  );
}