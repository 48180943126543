import * as React from 'react'
import TextField from '@mui/material/TextField'

export const TextAreaComponent = ({label, value, onChange, name, row, disabled = false}) => {

  const handleChange = (ev) => {
    if(onChange){
        onChange(ev);
    }
  }

  return (
      <TextField 
      id="outlined-multiline-flexible" 
      label={label} 
      variant="outlined" 
      size="small" 
      name={name} 
      onChange={handleChange} 
      value={ value } 
      multiline 
      rows={ row || 3} 
      fullWidth
      disabled={disabled || false}
      // InputProps={{    
      //     inputProps: { 
      //         readOnly: disabled,
      //     },
      // }}
      InputLabelProps={{
        shrink: true,
      }}
      />

  );
}