import { memo } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton, Stack, Grid } from '@mui/material'
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation'
import { makeStyles } from '@mui/styles'
import { ICON } from '../icons/Icons'
import { Components } from '../../Components'

const useStyles = makeStyles({
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogAction: {
        justifyContent: 'center'
    },
    titleIcon: {
        // backgroundColor: theme.palette.secondary.light,
        color: "#ffa500",
        '&:hover': {
            // backgroundColor: theme.palette.secondary.light,
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '4rem',
        }
    },
 });

export const ConfirmComponent = memo(({ confirmDialog, setConfirmDialog }) => {

    const clases = useStyles();

    const onClickYes = () => {
        confirmDialog.onConfirm();
        setConfirmDialog({ ...confirmDialog, isOpen: false })
    }

    const onClickNo = () => {
        if(confirmDialog?.noConfirm){
            confirmDialog?.noConfirm();
        }
        setConfirmDialog({ ...confirmDialog, isOpen: false })
    }

    return (
        <Dialog open={confirmDialog.isOpen}>
            <DialogTitle className={clases.dialogTitle}>
                <IconButton disableRipple className={clases.titleIcon}>
                    <NotListedLocationIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={clases.dialogContent}>
                <Typography variant="h6">
                    {confirmDialog.title}
                </Typography>
                <Typography variant="subtitle2">
                    {confirmDialog.subTitle || <></>}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
                            <Components.Button title="Yes" icon={ICON.CHECK} onClick={onClickYes} />
                            <Components.Button title="No" color="error" icon={ICON.CLOSE} onClick={onClickNo} />
                        </Stack>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
})