import { useState } from 'react'
import { useFormValidation } from '../../hooks/useFormValidation'
import { SecurityUtil } from '../../util/SecurityUtil'
import { Avatar, Paper, CssBaseline, Button, TextField, Box, Typography, InputAdornment, Container } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Components } from '../../framework/components/Components'
import { ICON } from './../../framework/components/form/icons/Icons'
import logo from '../../assets/img/logo.png'
import './LoginPage.css'

export const LoginPage = () => {
  const defaultTheme = createTheme();
  const [ loader, setLoader ] = useState(false);
  const [ showPassword, setShowPassword ] = useState(false);

  const initialValues = {
    username: '', 
    password: ''
  };

  const validate = (fieldValues = data) => {
      let temp = { ...errors }
      if ('username' in fieldValues){
          temp.username = (fieldValues.username === '' || fieldValues.username === null) ? 'Please enter username!' : '';
      }
      if ('password' in fieldValues){
          temp.password = (fieldValues.password === '' || fieldValues.password === null) ? 'Please enter password!' : '';
      }
      setErrors({ ...temp })

      if (fieldValues === data){
          return Object.values(temp).every(x => x === "")
      }
  }

  const { data, errors, setErrors, handleInputFormChange: handleInputChange } = useFormValidation(initialValues, true, validate);

  const handleLogin = (e) => {
    e.preventDefault(); 
    localStorage.clear();
    if(validate()){
      SecurityUtil.login(data, setLoader)
    }
  }

  const handlePress = (e) => {
      if(e.charCode === 13){
        handleLogin(e);
      }
  };
  
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component={Paper} maxWidth="xs" elevation={4} sx={{borderRadius: '10px 10px 10px 10px'}}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          
        >
          <Avatar sx={{ m: 1, width: 100, height: 100, backgroundColor: 'white'}}>
          <img
              src={logo}
              loading="lazy"
              height="80px !important"
              width="auto"
              alt="img_logo"
            />
          </Avatar>
          <Typography component="h6" variant="h6">
          Welcome to ForWard
          </Typography>
          <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }} autoComplete='off'>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="off"
              autoFocus
              size="small"
              onChange={ handleInputChange }
              value={data.username}
              InputLabelProps={{ shrink: true }}
              {...(errors['username'] && {error:true, helperText: errors['username'], required: true})}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              size="small"
              onKeyPress={ handlePress }
              onChange={ handleInputChange }
              value={data.password}
              InputProps={{          
                  
                endAdornment: <InputAdornment position="end">{(showPassword ? <Components.ButtonIconComponent className={'color-gray'} title="" icon={ICON.EYE_OFF} onClick={() => {setShowPassword(!showPassword)}}/>
                :
                <Components.ButtonIconComponent className={'color-gray'} title="" icon={ICON.EYE} onClick={() => {setShowPassword(!showPassword)}}/>)}</InputAdornment>,              
              }}
              InputLabelProps={{ shrink: true }}
              {...(errors['password'] && {error:true, helperText: errors['password'], required: true})}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loader}
              sx={{ mt: 3, mb: 2,
                    backgroundColor: '#132F4C',
                    color: '#FFFFFF',
                    '&:hover':{
                      backgroundColor: '#FFFFFF',
                      color: '#132F4C',
                    }
              }}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Container>
      {loader && <Components.SimpleBackdrop open={loader}/>}
    </ThemeProvider>
  );
};