import {
  Close, 
  Check, 
  Search, 
  Add, 
  AddCircle, 
  Edit, 
  Delete, 
  Save, 
  Print, 
  AccountCircle,
  ArrowBack, 
  PowerSettingsNew, 
  Download, 
  FileDownload, 
  Publish, 
  Brightness4, 
  Brightness7,
  FilterList, 
  DeleteSweep, 
  LocalPrintshop, 
  CameraAlt, 
  FlipCameraIos, 
  KeyboardArrowDown, 
  KeyboardArrowUp, 
  CheckCircle,
  Send,
  ChevronLeft,
  Notifications,
  Menu,
  History,
  Replay,
  Settings,
  ChevronRight,
  Info,
  CheckCircleOutline,
  AssignmentLate,
  Visibility,
  VisibilityOff,
  Analytics,
  Handyman,
  CloudDownload,
  InsertPageBreak,
  GroupRemove,
  AccessTime,
  ArrowForward,
  CloudSync,
  Home,
  Paid,
  MedicationLiquid,
  FactCheck,
  SupervisedUserCircle
} from '@mui/icons-material'

export const ICON = {
  CLOSE     : <Close/>,
  CLOSE_RED : <Close color="error"/>,
  CHECK     : <Check />,
  SEARCH    : <Search />,
  ADD       : <Add />,
  ADD_CIRCLE: <AddCircle />,
  EDIT      : <Edit />,
  DELETE    : <Delete />,
  BACK      : <ArrowBack/>,
  SAVE      : <Save/>,
  ON        : <PowerSettingsNew/>,
  DOWNLOAD  : <Download/>,
  UPLOAD    : <Publish/>,
  FILTER    : <FilterList/>,
  CANCEL    : <DeleteSweep/>,
  PRINT     : <Print/>,
  LOCAL_PRINT_SHOP: <LocalPrintshop/>,
  FILE_DOWNLOAD: <FileDownload/>,
  SUCCESS   : <CheckCircle color="success"/>,
  SEND      : <Send/>,
  LIGHT     : <Brightness7/>,
  DARK      : <Brightness4/>,
  ACCOUNT   : <AccountCircle/>,
  LEFT      : <ChevronLeft/>,
  NOTIFY    : <Notifications/>,
  MENU      : <Menu/>,
  HISTORY      : <History/>,
  REPLAY      : <Replay/>,
  KEY_DOWN      : <KeyboardArrowDown/>,
  KEY_UP      : <KeyboardArrowUp/>,
  SETTINGS  : <Settings/>,
  RIGHT     : <ChevronRight/>,
  INFO     : <Info/>,
  CHECK_CIRCLE: <CheckCircle/>,
  CHECK_CIRCLE_OUTLINE: <CheckCircleOutline/>,
  BADGE: <AssignmentLate/>,
  EYE       : <Visibility/>,
  EYE_OFF   : <VisibilityOff/>,
  ARROW_RIGHT: <ArrowForward/>,
  ANALYTICS: <Analytics />,
  HAND: <Handyman />,
  CLOUD: <CloudDownload color='warning'/>,
  FILE_EXCEL: <InsertPageBreak/>,
  GROUP_REMOVE: <GroupRemove/>,
  TIME_CLOCK: <AccessTime/>,
  CAMERA_SWITCH: <FlipCameraIos/>,
  CAMERA: <CameraAlt/>,
  SYNC: <CloudSync/>,
  HOME: <Home/>,
  MONEY: <Paid/>,
  SICK_DAY: <MedicationLiquid/>,
  CHECK_LIST: <FactCheck/>,
  USERS: <SupervisedUserCircle/>
};

export const ICONS = {
  FILE_PDF : 'far fa-file-pdf',
  FILE_EXCEL : 'far fa-file-excel',
  UPLOAD : 'fas fa-upload',
  TOOLS: 'fas fa-tools',
  CLOSE: 'fas fa-times',
  SAVE: 'fas fa-save',
};  