import { useState, useEffect, forwardRef } from 'react';
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { NavLink as RouterLink } from 'react-router-dom';
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { LoadUtil } from '../../util/LoadUtil';
import { EnvConstants } from '../../../EnvConstants';
import Tooltip from '@mui/material/Tooltip';

export const MenuLayout = ({open, options}) => {
  const [ listMenu, setListMenu ] = useState([]);
  const [ menu, setMenu ] = useState(options ||{});
  const [ menuChildren, setMenuChildren ] = useState(options ||{});

  const handleClick = (option) => {
    let newData = {[option.name] : !menu[option.name]};
    setMenu(newData);
  };

  const CustomRouterLink = forwardRef((props, ref) => (
      <div ref={ref} style={{ flexGrow: 1 }}>
        <RouterLink {...props}/>
      </div>
  ));

  const searchMenu = () => {
    LoadUtil.loadByQueryId({
        url: EnvConstants.GET_FW_FORM_URL_LIST_BASE(),
        queryId: 1,
        fnOk: (resp) => {
          let listMenuChildren = []
          const menu = resp?.dataList;
          menu.forEach( m => {
            const { optionId, name, url, icon, childrens } = m
            const listChildrens = JSON.parse(childrens) || []
            listMenuChildren = [...listMenuChildren, { optionId, name, url, icon, listChildrens }]
          })
          setListMenu(listMenuChildren)
        }
    });
  }

  useEffect(() => {
    searchMenu()
  }, [])

  useEffect(() => {
    if(Object.keys(options).length > 0 ){
      setMenu( { [options.parent]: true })
      setMenuChildren( { [options.option]: true })
    }
  }, [options])

  return (
      listMenu.length > 0 &&
      listMenu.map( (m,idx_m) => (
        <List component="div" disablePadding key={`principal-list-${idx_m}`}>
          <Tooltip title={m.name} key={`tool-tip-parent-${m.name}`} placement="right">
            <ListItemButton key={`list-parent-${m.name}`} onClick={() => handleClick(m)}>
              <ListItemIcon>
                <i className={m.icon} style={{color: 'white', textAlign: 'center'}}></i>
              </ListItemIcon>
              <ListItemText primary={m.name} />
              { menu[m.name] ? <ExpandLess /> : <ExpandMore /> }
            </ListItemButton>
          </Tooltip>    
          <Collapse in={ menu[ m.name ] ? true : false} timeout="auto" unmountOnExit key={`collapse-${m.name}`}>
            {
              m.listChildrens.length > 0 && 
              m.listChildrens.map( (c,idx) => (
                <Tooltip title={c.name} key={`tool-tip-${c.name}-${idx}`} placement="right">
                  <List component="div" disablePadding key={`principal-list-children-${m.name}-${idx}`}>
                      <ListItemButton key={`children-${c.name}_${idx}`} sx={{ pl: open ? 4 : menu[m.name] ? 2 : 0, ":hover" : { backgroundColor : 'gray'}, backgroundColor: menuChildren[c.name] ? 'gray': '' }} component={CustomRouterLink} to={c.url}>
                        <ListItemIcon>
                          <i className={c.icon} style={{ color: '#B3E884', textAlign: 'center' }}></i>
                        </ListItemIcon>
                        <ListItemText primary={c.name}/>
                      </ListItemButton>
                  </List>
                </Tooltip>    
              ))
            }
          </Collapse>
      </List>
      ))
  )
}