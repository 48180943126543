import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

export const InputComponent = React.memo(({label, value, onChange, onBlur, name, disabled, type, className , error = null, min, max, precision, icon, autoComplete, onEnter, required, align, maxLength, variant = 'outlined', sx }) => {
  
  const handleChange = (e) => {
    if(onChange){
        onChange(e);
    }
  }
  
  const handleInput = (e) => {
    if(type === 'decimal'){
      let t = e.target.value;
      let pre = (precision) ? (precision + 1) : 4;
      e.target.value = ((t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), pre)) : t);
    }
  }

  const handleBlur = (e) => {
    if(onBlur){
      onBlur(e);
    }
  }


  const handlePress = (e) => {
    if(onEnter){
      if(e.charCode === 13){
        onEnter();
      }
    }
    if(type === 'decimal'){
      if((e.charCode >= 58 && e.charCode <=127) || (e.charCode >= 33 && e.charCode <=44) || (e.charCode === 47) || (e.charCode === 45)) {
        e.preventDefault()
      }
    }
  }

  return (
        <TextField
        // color='primary'
        // focused
        sx={{ width: "100%", ...sx }} 
        className={className || ''}
        id="outlined-basic"
        label={label} 
        type={(type ===' decimal' || type) ? "text" : type} 
        variant={variant} 
        size="small" 
        name={name} 
        onInput={handleChange} 
        value={ (value === undefined || value === null || value === '') ? '' : value } 
        fullWidth={true} 
        // onInput={handleInput}
        onKeyPress={handlePress}
        onBlur={handleBlur}
        autoComplete={autoComplete || 'off'}
        required={required || false}
        disabled={disabled || false}
        // minDate={(type == 'datetime-local' || type == 'date' ? min : undefined)}
        // maxDate={(type == 'datetime-local' || type == 'date' ? max : undefined)}        
        InputProps={{    
          inputProps: { 
              max, 
              min,
              type,
              // readOnly: disabled || false,
              style: { textAlign: align || "left" },
              maxLength
          },
          // startAdornment: <InputAdornment position="end">kg</InputAdornment>,
          endAdornment: <InputAdornment position="end">{icon || ''}</InputAdornment>,
          
        }}
        InputLabelProps={{
          shrink: (type === 'datetime-local' || type === 'date' || type === 'time' ? true : undefined),
        }}
        {...(error && {error:true, helperText: error, required: true})}
        />
  );
})